import { Blob } from "buffer";
import { DeviceServerEvents, Response } from "./types";

export const parseJson = (json: string | ArrayBuffer | Blob): Response | null => {
  try {
    if (typeof json === "string") {
      return JSON.parse(json);
    }
    return null;
  } catch (ex) {
    return null;
  }
};

/**
 * Check if device service returned a critical error
 * @param response Response
 * @returns boolean
 */

export const isError = (response: Response): boolean => {
  return response.event === DeviceServerEvents.REQUEST_ERROR;
};

export const isRunningInSimulator = window && window.location && window.location.hostname.split(".")[0] === "simulator";
