import WebSocketAsPromised from "websocket-as-promised";

export enum SupportedServices {
  IngenicoPed = "INGENICO_PED",
  ReceiptPrinter = "RECEIPT_PRINTER",
  Scanner = "SCANNER",
  LabelPrinter = "LABEL_PRINTER",
  MagneticStripeCardReader = "MSR",
  Admin = "ADMIN",
}

export type ActionResponse = Record<string, unknown>;

export enum DeviceServerEvents {
  REQUEST_ERROR = "REQUEST_ERROR",
  DEVICE_RESPONSE = "DEVICE_RESPONSE",
  COUNTER_DISPLAY_MESSAGE = "COUNTER_DISPLAY_MESSAGE",
  RECEIPT = "RECEIPT",
}

export type PosDisplayEvent = {
  label: string;
  event: "CONTINUE" | "CANCEL";
};

export type ServiceEvent = {
  service: SupportedServices;
  action: string;
  message: string;
  availableEvents?: Array<PosDisplayEvent>;
};

export type Response = {
  service: SupportedServices;
  action: string;
  event: DeviceServerEvents;
  message?: string;
  result?: Record<string, unknown>;
  availableEvents?: Array<PosDisplayEvent>;
};

export type Callbacks = {
  onDisplayUpdate?: (event: ServiceEvent) => void;
  onReceiptContent?: (event: ServiceEvent) => void;
  onConnectionOpened?: () => void;
  onConnectionClosed?: () => void;
  onConnectionError?: () => void;
};

export type Setup = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildClient: (name: any, props: any) => unknown;
  connection: WebSocketAsPromised;
};

export interface ClientProps {
  deviceServerHost: string;
  requestTimeout?: number;
  connectionTimeout?: number;
  disconnectAfterResponse?: boolean;
  callbacks?: Callbacks;
}

export enum ErrorCodes {
  ActionNotPermitted = "ACTION_NOT_PERMITTED",
  UnknownError = "UNKNOWN_ERROR",
}
