import { PedActions, ReceiptMessageMappings } from "../types";
import bankingUndoCodes from "./bankingUndoCodes";
import { AcquirerResponseCodes } from "./acquirierResponseCodes";

const ALL_TRANSACTION_TYPES = [
  PedActions.BalanceEnquiryY,
  PedActions.DepositY,
  PedActions.WithdrawalY,
  PedActions.ChangePinY,
  PedActions.DebitY,
  PedActions.RefundY,
];

// OMO15
export const CHANGE_PIN_FALLBACK_MESSAGE = {
  en: "PIN change request not confirmed. It is not known if the PIN has been changed. You may wish to contact the card issuer.",
  cy: "Ni chadarnhawyd y cais i newid y PIN. Ni wyddom a yw'r PIN wedi newid. Efallai yr hoffech gysylltu â dyroddwr y cerdyn.\nPIN change request not confirmed. It is not known if the PIN has been changed. You may wish to contact the card issuer.",
};

export const INDETERMINATE_FALLBACK_MESSAGE =
  "We have not been able to complete your transaction at this time, this is due to a system issue. You may wish to speak to your Bank or Card Issuer for any further information.";

// when we have a scenario where we've received a banking undo response
// code, we don't get a receipt, we fallback to the following
export const INDETERMINATE_FALLBACK_RECEIPT = `$$$UserData1$$$\n\n$$$outcome$$$`;

// OMO23
export const DEFAULT_FALLBACK_MESSAGE = {
  en: "Unable to complete the transaction. Sorry for any inconvenience caused.",
  cy: "Yn methu cwblhau'r gweithrediad.Ymddiheurwn am unrhyw drafferth.\nUnable to complete the transaction. Sorry for any inconvenience caused.",
};

// OM003
export const FEE_ACCEPTED_MESSAGES = {
  en: "The Post Office has not charged you for this transaction. The card issuer has charged you %TransactionFee%.",
  cy: "Dydy Swyddfa'r Post ddim wedi codi tâl arnoch am y gweithrediad hwn. Mae dyroddwr y cerdyn wedi codi %TransactionFee% arnoch.\nThe Post Office has not charged you for this transaction. The card issuer has charged you %TransactionFee%.",
};

export const MESSAGE_MAPPINGS = [
  {
    id: "Invalid Pin Pad Response",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: bankingUndoCodes,
    acquirerResponseCodes: [],
    messages: {
      en: INDETERMINATE_FALLBACK_MESSAGE,
      cy: INDETERMINATE_FALLBACK_MESSAGE,
    },
  },
  {
    id: "OM004",
    transactionTypes: [
      PedActions.BalanceEnquiryY,
      PedActions.DepositY,
      PedActions.WithdrawalY,
      PedActions.ChangePinY,
      PedActions.DebitY,
      PedActions.RefundY,
    ],
    c3CombinedErrorCodes: [
      "0310/0085", // customer didn't accept fees
    ],
    acquirerResponseCodes: [],
    messages: {
      en: "You have not been charged for this transaction.",
      cy: "Ni chafodd tâl ei godi arnoch am ygweithrediad hwn.\nYou have not been charged for this transaction.",
    },
  },
  {
    id: "OM005",
    transactionTypes: [PedActions.BalanceEnquiryY, PedActions.DepositY, PedActions.WithdrawalY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.IncorrectPin],
    messages: {
      en: "Incorrect PIN supplied. You may retry.",
      cy: "Rhoddwyd PIN anghywir. Gallwch ailgynnig.\nIncorrect PIN supplied. You may retry.",
    },
  },
  {
    id: "OM006",
    transactionTypes: [PedActions.ChangePinY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.IncorrectPin],
    messages: {
      en: "Incorrect PIN supplied. The PIN has not been changed. You may retry.",
      cy: "Rhoddwyd PIN anghywir. Ni chafodd y PINei newid. Gallwch ailgynnig.\nIncorrect PIN supplied. The PIN has not been changed. You may retry.",
    },
  },
  {
    id: "OM007",
    transactionTypes: [PedActions.BalanceEnquiryY, PedActions.DepositY, PedActions.WithdrawalY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded],
    messages: {
      en: "The PIN is now blocked. You may wish to contact the card issuer.",
      cy: "Cafodd y PIN ei atal nawr. Efallai yrhoffech gysylltu â dyroddwr y cerdyn.\nThe PIN is now blocked. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM008",
    transactionTypes: [PedActions.ChangePinY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded],
    messages: {
      en: "The PIN has not been changed. Current PIN is blocked. You may wish to contact the card issuer.",
      cy: "Ni chafodd y PIN ei newid. Cafodd y PINcyfredol ei atal. Efallai yr hoffechgysylltu â dyroddwr y cerdyn.\nThe PIN has not been changed. Current PIN is blocked. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM009",
    transactionTypes: [PedActions.ChangePinY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded],
    messages: {
      en: "The PIN has not been changed. Current PIN is blocked. You may wish to contact the card issuer.",
      cy: "Ni chafodd y PIN ei newid. Cafodd y PINcyfredol ei atal. Efallai yr hoffechgysylltu â dyroddwr y cerdyn.\nThe PIN has not been changed. Current PIN is blocked. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM009",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [
      AcquirerResponseCodes.InvalidTransaction,
      AcquirerResponseCodes.TransactionNotPermittedToCardholder,
    ],
    messages: {
      en: "The transaction has been declined by the customer's card.",
      cy: "Gwrthodwyd y gweithrediad gan gerdyn y cwsmer.\nThe transaction has been declined by the customer's card.",
    },
  },
  {
    id: "OM010",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [
      AcquirerResponseCodes.PickUp,
      AcquirerResponseCodes.PickUpSpecialConditions,
      AcquirerResponseCodes.ExpiredCardPickup,
      AcquirerResponseCodes.SuspectedFraudPickup,
      AcquirerResponseCodes.CardAcceptorContactAcquirerPickup,
      AcquirerResponseCodes.RestrictedCardPickUp,
      AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup,
      AcquirerResponseCodes.AllowablePinTriesExceededPickup,
      AcquirerResponseCodes.LostCardPickUp,
      AcquirerResponseCodes.StolenCardPickUp,
      AcquirerResponseCodes.HardCapturePickUp,
      AcquirerResponseCodes.CVCFailedCaptureCard,
    ],
    messages: {
      en: "The card issuer requested that your card be retained. You may wish to contact the card issuer.",
      cy: "Mae dyroddwr y cerdyn wedi gwneud cais i'r cerdyn gael ei gadw.Efallai yr hoffech gysylltu â dyroddwr ycerdyn.\nThe card issuer requested that your card be retained. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM011",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.ExceedsWithdrawalFrequencyLimit],
    messages: {
      en: "Exceeded maximum number of allowable daily transactions. You may wish to contact the card issuer.",
      cy: "Rydych wedi cwblhau'r holl weithrediadau a ganiateir mewn diwrnod. Efallai yr hoffech gysylltu â dyroddwr y cerdyn.\nExceeded maximum number of allowable daily transactions. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM012",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.ExceedsWithdrawalAmountLimit],
    messages: {
      en: "Requested amount would exceed daily card limit. You may wish to contact the card issuer.",
      cy: "Byddai'r swm y gwnaed cais amdano yn mynd y tu hwnt i derfyn dyddiol y cerdyn.Efallai yr hoffech gysylltu â dyroddwr y cerdyn.\nRequested amount would exceed daily card limit. You may wish to contact the card issuer.",
    },
  },
  {
    id: "OM013",
    transactionTypes: ALL_TRANSACTION_TYPES,
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [AcquirerResponseCodes.InsufficientFunds],
    messages: {
      en: "Insufficient funds.",
      cy: "Arian annigonol.\nInsufficient funds.",
    },
  },
  {
    id: "OM014",
    transactionTypes: ALL_TRANSACTION_TYPES,
    acquirerResponseCodes: [],
    c3CombinedErrorCodes: [
      "0310/0017", // cancelled by clerk or by user
      "0311/0014", // customer removed card too early
    ],
    messages: {
      en: "Transaction cancelled.",
      cy: "Diddymwyd y gweithrediad.\nTransaction cancelled.",
    },
  },
  {
    id: "OMO15",
    transactionTypes: [PedActions.ChangePinY],
    acquirerResponseCodes: [],
    c3CombinedErrorCodes: [
      "0310/0017", // cancelled by clerk or by user
      "0311/0014", // customer removed card too early
    ],
    messages: {
      en: "Transaction cancelled.",
      cy: "Diddymwyd y gweithrediad.\nTransaction cancelled.",
    },
  },
  {
    id: "OMO16",
    transactionTypes: [PedActions.BalanceEnquiryY, PedActions.DepositY, PedActions.WithdrawalY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [
      AcquirerResponseCodes.ReferToCardIssuer,
      AcquirerResponseCodes.ReferToCardIssuerSpecialConditions,
      AcquirerResponseCodes.DoNotHonour,
      AcquirerResponseCodes.InvalidAmount,
      AcquirerResponseCodes.InvalidCardNumber,
      AcquirerResponseCodes.NoCreditAccount,
      AcquirerResponseCodes.NoUniversalAccount,
      AcquirerResponseCodes.NoInvestmentAccount,
      AcquirerResponseCodes.NoCheckingAccount,
      AcquirerResponseCodes.NoSavingsAccount,
      AcquirerResponseCodes.ExpiredCard,
      AcquirerResponseCodes.SuspectedFraudDecline,
      AcquirerResponseCodes.CardAcceptorContactAcquirerDecline,
      AcquirerResponseCodes.RestrictedCard,
      AcquirerResponseCodes.MasterCardSecurityViolation,
      AcquirerResponseCodes.CardAcceptorContactAcquirerSecurity,
      AcquirerResponseCodes.CardAuthenticationFailure,
      AcquirerResponseCodes.CVCFailedDoNotHonour,
      AcquirerResponseCodes.CVV2FailedDoNotHonour,
    ],
    messages: {
      en: "You may wish to contact the card issuer.",
      cy: "Efallai yr hoffech gysylltu â dyroddwr ycerdyn.\nYou may wish to contact the card issuer.",
    },
  },
  {
    id: "OMO17",
    transactionTypes: [PedActions.ChangePinY],
    c3CombinedErrorCodes: [],
    acquirerResponseCodes: [
      AcquirerResponseCodes.ReferToCardIssuer,
      AcquirerResponseCodes.ReferToCardIssuerSpecialConditions,
      AcquirerResponseCodes.DoNotHonour,
      AcquirerResponseCodes.InvalidAmount,
      AcquirerResponseCodes.InvalidCardNumber,
      AcquirerResponseCodes.NoCreditAccount,
      AcquirerResponseCodes.NoUniversalAccount,
      AcquirerResponseCodes.NoInvestmentAccount,
      AcquirerResponseCodes.NoCheckingAccount,
      AcquirerResponseCodes.NoSavingsAccount,
      AcquirerResponseCodes.ExpiredCard,
      AcquirerResponseCodes.SuspectedFraudDecline,
      AcquirerResponseCodes.CardAcceptorContactAcquirerDecline,
      AcquirerResponseCodes.RestrictedCard,
      AcquirerResponseCodes.MasterCardSecurityViolation,
      AcquirerResponseCodes.CardAcceptorContactAcquirerSecurity,
      AcquirerResponseCodes.CardAuthenticationFailure,
      AcquirerResponseCodes.CVCFailedDoNotHonour,
      AcquirerResponseCodes.CVV2FailedDoNotHonour,
    ],
    messages: {
      en: "The PIN has not been changed.\nYou may wish to contact the card issuer.",
      cy: "Ni chafodd y PIN ei newid.Efallai yr hoffech gysylltu â dyroddwr y cerdyn.\nThe PIN has not been changed. You may wish to contact the card issuer.",
    },
  },
] as ReceiptMessageMappings;
