export interface LabelPrinterPrintResult {
  printed: boolean;
}

export interface LabelPrinterCalibrateResult {
  calibrated: boolean;
}

export interface LabelPrinterClient {
  print(props: LabelPrinterPrintProps): Promise<LabelPrinterPrintResult>;
  calibrate(): Promise<LabelPrinterCalibrateResult>;
  printConfigurationLabels(): Promise<LabelPrinterPrintResult>;
}

export interface LabelPrinterPrintProps {
  printer: LabelAvailablePrinters;
  label: string;
}

export interface LabelPrinterClientProps {
  useMock?: boolean;
}

export enum LabelPrinterActions {
  Print = "PRINT",
  Calibrate = "CALIBRATE",
  PrintConfigurationLabels = "PRINT_CONFIGURATION",
}

export enum LabelAvailablePrinters {
  RoyalMail = "label_printer_1",
  MonarchsHead = "label_printer_2",
}

/*
 * Token Mapping avaliable on confluence here:
 * https://pol-jira.atlassian.net/wiki/spaces/SPM/pages/10804855306/Postage+Sale+-+MH+Tokens+Mapping
 */

export interface monarchHeadLabelParams {
  prn: string;
  labelTemplate: string | null;
  requiredAtDelivery: string;
  returnToSenderPostCode: string;
  destinationCountry: string;
  upuTrackingNumber: string;
  serviceId: string;
  weightType: string;
  terminalID: string;
  fadCode: string;
  service: string;
  destAddress1?: string;
  postcode: string;
  town?: string;
  uniqueID?: string;
  weight?: string;
  price: string;
  date: string;
  vatCode: string;
  sessionID: string;
  segCode: string;
  satDel?: string;
  twoDBarcode?: string;
  barCodeReq?: string;
  altServiceId?: string;
}
