import { PedActions, PedResult, SimulatorEventTypes, TransactionTypes } from "../types";
import pedResponseCodes from "../mappings/pedResponseCodes";
import {
  extractMessaging,
  extractMetadata,
  isApproved,
  setFallbackMessaging,
  SUCCESSFUL_TRANSACTION_PROMPT,
} from "../helpers";
import { AcquirerResponseCodes } from "../mappings/acquirierResponseCodes";

interface EventData {
  event: string;
  pan: string;
  type: TransactionTypes;
  acquirerResponseCode?: string;
  combinedCode?: string;
}

export const OrderXResponse: PedResult = {
  paymentId: "123456789",
  transactionDate: "20220620",
  transactionTime: "122121",
  expiryDate: "20250101",
  errorCode: "0000",
  responseCode: "0000",
  combinedCode: "0000/0000",
  approved: true,
};

export const OrderYResponse: PedResult = {
  expiryDate: "20250101",
  paymentId: "123456789",
  transactionDate: "20220620",
  transactionTime: "122121",
  customerTicket: "Customer Ticket Receipt Content",
  merchantTicket: "Merchant Ticket Receipt Content",
  merchantNumber: "123456789",
  authorisationNumber: "123456789",
  combinedCode: "0000/0000",
  errorCode: "0000",
  responseCode: "0000",
  approved: true,
};

export const AbortResponse: PedResult = {
  approved: true,
  responseCode: "0017",
  errorCode: "0310",
  combinedCode: "0310/0017",
};

export const performAction = async (action: PedActions): Promise<PedResult> => {
  const isOrderX: boolean = [
    PedActions.BalanceEnquiryX,
    PedActions.WithdrawalX,
    PedActions.AbortX,
    PedActions.ChangePinX,
    PedActions.DepositX,
    PedActions.WithdrawalX,
    PedActions.DebitX,
    PedActions.RefundX,
  ].includes(action);

  // removes the need for an additional
  // cancel on fake ped in simulator
  if (action === PedActions.AbortX) {
    return Promise.resolve(AbortResponse);
  }

  return new Promise<PedResult>((resolve, reject) => {
    setTimeout(() => {
      const peripheralSelectorBroadcast = new BroadcastChannel("Peripherals");
      peripheralSelectorBroadcast.postMessage("INGENICO_PED");
      peripheralSelectorBroadcast.close();
    }, 500);

    const ingenicoPedChannel: BroadcastChannel = new BroadcastChannel("IngenicoPed");
    ingenicoPedChannel.onmessage = async (event: MessageEvent) => {
      const eventData = event.data as EventData;

      ingenicoPedChannel.close();

      let response: Record<string, unknown> = {
        pan: eventData.pan,
      };

      if (isOrderX) {
        response = { ...response, ...OrderXResponse };
      } else {
        response = { ...response, ...OrderYResponse };
      }

      switch (eventData.event) {
        case SimulatorEventTypes.MissingConfiguration:
          response = {
            errorCode: "0900",
            responseCode: "0000",
            combinedCode: "0900/0000",
            approved: false,
          };
          break;
        case SimulatorEventTypes.CardRemovedEarly:
          response = {
            errorCode: "0311",
            responseCode: "0014",
            combinedCode: "0311/0014",
            approved: false,
          };
          break;
        case SimulatorEventTypes.CustomerAborted:
          response = {
            errorCode: "0311",
            responseCode: "0017",
            combinedCode: "0310/0017",
            approved: false,
          };
          break;
        case SimulatorEventTypes.ExpiredCard:
          response = {
            ...response,
            errorCode: "0311",
            responseCode: "0033",
            combinedCode: "0311/0033",
            acquirerResponseCode: "54",
            approved: false,
          };
          break;
        case SimulatorEventTypes.InvalidTPV:
          response = {
            errorCode: "0311",
            responseCode: "0016",
            combinedCode: "0311/0016",
            approved: false,
          };
          break;
        case SimulatorEventTypes.Declined:
          response = {
            ...response,
            errorCode: "0311",
            responseCode: "0004",
            combinedCode: "0311/0004",
            acquirerResponseCode: "51",
            approved: false,
          };
          break;
        case SimulatorEventTypes.Approved:
          response = {
            ...response,
            acquirerResponseCode: "00",
          };
          break;
        default:
          throw Error(`Unknown simulator scenario: ${eventData.event}`);
      }

      const allowedApprovalCodes = [AcquirerResponseCodes.Approved, AcquirerResponseCodes.NoReasonToDecline];

      // test team were confusing the usage of response codes
      const isSupportedApprovalCode =
        eventData.event == SimulatorEventTypes.Approved &&
        allowedApprovalCodes.includes(eventData.acquirerResponseCode as AcquirerResponseCodes);

      // allow client to override acquirier response code
      if (
        isSupportedApprovalCode ||
        (eventData.event !== SimulatorEventTypes.Approved && eventData.acquirerResponseCode)
      ) {
        response = {
          ...response,
          acquirerResponseCode: eventData.acquirerResponseCode,
        };
      }

      if (eventData.combinedCode) {
        response.combinedCode = eventData.combinedCode;
      }

      const _response = response as PedResult;

      _response.metadata = extractMetadata(action, _response);

      if (!isApproved(_response)) {
        const prompt = extractMessaging(eventData.type, _response);

        if (prompt) {
          _response.prompt = prompt;
        }

        // attempt to fallback to c3 response code errors
        if (!prompt && pedResponseCodes[_response.combinedCode]) {
          _response.prompt = {
            id: pedResponseCodes[_response.combinedCode].id,
            description: pedResponseCodes[_response.combinedCode].description,
          };
        }

        // messaging fallback
        if (!_response.prompt) {
          _response.prompt = setFallbackMessaging(eventData.type);
        }

        return reject(_response);
      }

      _response.prompt = SUCCESSFUL_TRANSACTION_PROMPT;

      return resolve(_response);
    };
  });
};
