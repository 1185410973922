import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response } from "../../types";
import { isError } from "../../helpers";

import {
  LabelPrinterClient,
  LabelPrinterActions,
  LabelPrinterClientProps,
  LabelPrinterPrintResult,
  LabelPrinterCalibrateResult,
  LabelPrinterPrintProps,
} from "./types";
import { getMappedErrorCode } from "./mappings/errors";

/**
 * Build Client
 * @param requestHandler WebSocketAsPromised["sendRequest"]
 * @param clientProps LabelPrinterClientProps
 * @returns
 */
export const buildLabelPrinterClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  clientProps?: LabelPrinterClientProps
): LabelPrinterClient => {
  const performAction = async (
    action: LabelPrinterActions,
    params?: LabelPrinterPrintProps
  ): Promise<LabelPrinterPrintResult | LabelPrinterCalibrateResult> => {
    const response = (await requestHandler({
      service: SupportedServices.LabelPrinter,
      action,
      params,
    })) as Response;

    if (isError(response)) {
      if (response.message) {
        response.message = getMappedErrorCode(response.message);
      }
      return Promise.reject(response);
    }

    switch (action) {
      case LabelPrinterActions.Print:
      case LabelPrinterActions.PrintConfigurationLabels:
        return Promise.resolve(response.result as unknown as LabelPrinterPrintResult);
      case LabelPrinterActions.Calibrate:
        return Promise.resolve(response.result as unknown as LabelPrinterCalibrateResult);
      default:
        throw new Error(`Unexpected action: ${action}`);
    }
  };

  /**
   * Print Label
   * @param props LabelPrinterPrintProps
   * @returns LabelPrinterPrintResult
   */
  const print = async (props: LabelPrinterPrintProps): Promise<LabelPrinterPrintResult> => {
    return new Promise((resolve) => {
      if (clientProps?.useMock) {
        const peripheralSelectorBroadcast: BroadcastChannel = new BroadcastChannel("Peripherals");
        peripheralSelectorBroadcast.postMessage("LABEL");
        peripheralSelectorBroadcast.close();
        setTimeout(() => {
          const printBroadcast: BroadcastChannel = new BroadcastChannel("label");
          printBroadcast.postMessage("label printed");
          printBroadcast.close();
        }, 500);
        return resolve({ printed: true });
      }

      const result = performAction(LabelPrinterActions.Print, {
        label: props.label,
        printer: props.printer,
      });

      return resolve(result as unknown as LabelPrinterPrintResult);
    });
  };

  /**
   * Calibrate Printers
   * @returns LabelPrinterCalibrateResult
   */
  const calibrate = async (): Promise<LabelPrinterCalibrateResult> => {
    return new Promise((resolve) => {
      if (clientProps?.useMock) {
        return resolve({ calibrated: true });
      }

      const result = performAction(LabelPrinterActions.Calibrate);

      return resolve(result as unknown as LabelPrinterCalibrateResult);
    });
  };

  /**
   * Print Configuration Label
   * @param props LabelPrinterPrintProps
   * @returns LabelPrinterPrintResult
   */
  const printConfigurationLabels = async (): Promise<LabelPrinterPrintResult> => {
    return new Promise((resolve) => {
      if (clientProps?.useMock) {
        return resolve({ printed: true });
      }

      const result = performAction(LabelPrinterActions.PrintConfigurationLabels);
      return resolve(result as unknown as LabelPrinterPrintResult);
    });
  };

  return Object.freeze({
    print,
    calibrate,
    printConfigurationLabels,
  });
};
