import { PosDisplayEvent } from "../../types";
import { AcquirerResponseCodes } from "./mappings/acquirierResponseCodes";

export interface IngenicoPedClient {
  debitCheck(amount: number, transactionId: string): Promise<PedResult>;
  debit(amount: number, transactionId: string): Promise<PedResult>;
  withdrawalCheck(amount: number, transactionId: string): Promise<PedResult>;
  withdrawal(amount: number, transactionId: string): Promise<PedResult>;
  depositCheck(amount: number, transactionId: string): Promise<PedResult>;
  deposit(amount: number, transactionId: string, skipPin?: boolean): Promise<PedResult>;
  refundCheck(amount: number, transactionId: string): Promise<PedResult>;
  refund(amount: number, transactionId: string): Promise<PedResult>;
  changePinCheck(transactionId: string): Promise<PedResult>;
  changePin(transactionId: string): Promise<PedResult>;
  balanceEnquiryCheck(transactionId: string): Promise<PedResult>;
  balanceEnquiry(transactionId: string, skipPin?: boolean): Promise<PedResult>;
  sendEvent(event: PosDisplayEvent["event"]): Promise<void>;
  abort(transactionId: string): Promise<PedResult>;
  version(): Promise<PedVersionResult>;
  initialise(): Promise<boolean>;
}

export interface IngenicoPedClientProps {
  terminalId: string;
  clerkId: string;
  useMock: boolean;
}

export type MessageMapping = {
  [key: string]: MessagePrompt;
};

export type MessagePrompt = {
  id: string;
  description: string;
};

export enum TransactionTypes {
  Banking = "banking",
  Payments = "payments",
}

export enum ReceiptLanguage {
  English = "en",
  Welsh = "cy",
}

export enum PedActions {
  Initialise = "INITIALISE",
  WithdrawalX = "WITHDRAWAL_X",
  WithdrawalY = "WITHDRAWAL_Y",
  BalanceEnquiryX = "BALANCE_ENQUIRY_X",
  BalanceEnquiryY = "BALANCE_ENQUIRY_Y",
  AbortX = "ABORT_X",
  DebitX = "DEBIT_X",
  DebitY = "DEBIT_Y",
  DepositX = "DEPOSIT_X",
  DepositY = "DEPOSIT_Y",
  ChangePinX = "CHANGE_PIN_X",
  ChangePinY = "CHANGE_PIN_Y",
  RefundX = "REFUND_X",
  RefundY = "REFUND_Y",
  GetVersion = "GET_VERSION",
  POSEvent = "POS_EVENT",
}

export type PedResult = {
  expiryDate?: string;
  paymentId?: string;
  errorCode?: string;
  transactionDate?: string;
  transactionTime?: string;
  customerTicket?: string;
  merchantTicket?: string;
  merchantNumber?: string;
  authorisationNumber?: string;
  responseCode: string;
  combinedCode: string;
  acquirerResponseCode?: string;
  tokenisedPan?: string;
  entryMode?: string;
  pan?: string;
  approved?: boolean;
  additionalAmounts?: string[];
  transactionProcessingFeeAmount?: string;
  customerFeeAmount?: string;
  metadata?: Metadata;
  prompt?: MessagePrompt;
  balances?: AccountBalanceItem[];
};

export type AccountBalanceItem = {
  accountType: string;
  amountType: string;
  amount: number;
  currency: string;
};

export type Metadata = {
  resultCode?: string;
  responseCode?: string;
  cardImpounded?: boolean;
  transactionType?: number;
};

export type InternalToExternalMappingT = {
  [name: string]: Metadata;
};

export type PedError = {
  message: string;
} & PedResult;

export type PedInitialisationResult = {
  initialised: boolean;
};

export type PedVersionResult = {
  ticket: string;
  version: string;
};

export enum SimulatorEventTypes {
  Approved = "Approved",
  Declined = "Declined",
  ExpiredCard = "ExpiredCard",
  CardRemovedEarly = "CardRemovedEarly",
  MissingConfiguration = "MissingConfiguration",
  CustomerAborted = "CustomerAborted",
  InvalidTPV = "InvalidTPV",
}

export interface PedResponseCode {
  [key: string]: {
    id: string;
    description: string;
    resultCode?: string;
    responseCode?: string;
  };
}

export interface ReceiptMessageMapping {
  id: string;
  transactionTypes: PedActions[];
  c3CombinedErrorCodes: string[];
  acquirerResponseCodes: AcquirerResponseCodes[];
  messages: {
    en: string;
    cy: string;
  };
}

export type ReceiptMessageMappings = ReceiptMessageMapping[];
