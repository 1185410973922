import {
  parcelRefNumberHyphenated,
  getRightBottomColumn,
  getAddress,
  getVATcolumn,
  getVATrow,
  generateTwoDBarcode,
  generateOneDBarcode,
} from "../helpers";
import { monarchHeadLabelParams } from "../types";

// TODO - New type
export const generateMonarchHeadLabel = (params): string => {
  const { labelTemplate } = params;

  if (labelTemplate === "postageLabel2d") {
    return getMonarchsHeadWithTwoDBarcode(params);
  }

  return getMonarchsHead(params);
};

export const getMonarchsHeadWithTwoDBarcode = (params: monarchHeadLabelParams): string => {
  const { satDel, upuTrackingNumber, service, barCodeReq, prn } = params;
  const prnHyphenated = parcelRefNumberHyphenated(prn);
  const twoDBarcode = generateTwoDBarcode(params, prn);

  let oneDBarcode = "";
  let vat = "";

  if (twoDBarcode === typeof null || twoDBarcode === null) {
    throw new Error("Barcode generation failed");
  }

  if (barCodeReq === "true" && upuTrackingNumber) {
    oneDBarcode = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  // An excerpt of address to appear on the label.
  // If postcode (zip code) is absent, print Town.
  // Print premises (building name/number) and first 6 char of Street
  // (if present). Below that, the CountryDisplayName (MAIL-1224)

  // 40 symbols max to fit in the area

  return `^XA
^LT24^LS22^CF0,52^FO100,12^FD${service}^FS${
    satDel === "SAT" && "^FS^PR1^FO640,2^GB98,60,50,0^FS^FO654,12^CF0,52,46^FR^FDSAT^FS^CF0,33"
  }

^FX --- Data Matrix bar code ---
^FO100,95^BXN,6,200,44,44,2^FD${twoDBarcode}^FS

^FX --- Bottom content ---
^CF0,33
^FO100,400^FD${prnHyphenated}^FS
^FX--- Bar code ---

${oneDBarcode}

${vat}
${getAddress(params)}
${getRightBottomColumn(params)}
^MMC,N
^XZ
`;
};

export const getMonarchsHead = (params: monarchHeadLabelParams): string => {
  const { satDel, upuTrackingNumber, service, barCodeReq, altServiceId } = params;

  let oneDBarcode = "";
  let vat = "";

  if (barCodeReq === "true" && upuTrackingNumber) {
    oneDBarcode = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  return `^XA
^LT24^LS22
^CF0,52^FO100,12^FD${service}^FS${
    satDel === "SAT" && "^FS^PR1^FO640,2^GB98,60,50,0^FS^FO654,12^CF0,52,46^FR^FDSAT^FS^CF0,33"
  }

^CF0,180^FO130,120^FD^CF0,180^FO130,120^FD${altServiceId}^FS^FS
^FX--- Bar code ---
${oneDBarcode}
${vat}
${getAddress(params)}
${getRightBottomColumn(params)}
^MMC,N
^XZ
`;
};
