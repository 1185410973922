import { monarchHeadLabelParams } from "./types";

const additionalParams = {
  upuCountryId: "JGB",
  infoTypeId: 4,
  versionId: 2,
  versionNumber: "1",
  mailType: "A",
  channel: "2",
  mailPieceType: " ",
  format: "8",
  class: "6",
};

// This is the same as uniqueID
export const parcelRefNumberHyphenated = (prn: string): string => {
  const splitByFourSymbols = prn.match(/.{1,4}/g) || [];

  return splitByFourSymbols.join("-");
};

export const getRightBottomColumn = (params: monarchHeadLabelParams): string => {
  return `^FX --- Date, Seg code ---

^FO1050,270^FB130,3,0,R,0^FD${params.date} \\& ${params.weight ? parseInt(params.weight, 10) / 1000 : "0"}kg \\& ${
    parseInt(params.price, 10) / 100
  }^FS

^FX --- Circle, Seg code ---
^FO1080,400^GC200,3,B^FS^CF0,60^FO1130,445^FD${params.segCode}^FS
`;
};

export const getVATcolumn = (params: monarchHeadLabelParams): string => {
  const { terminalID, vatCode, sessionID, fadCode } = params;

  return `
^FX --- y6 ---
^CF0,36^FO960,360^FDVAT ${vatCode}^FS

^FX --- Bottom Right content ---
^CFA,36,8^FO960,400^FD${terminalID}-${sessionID}^FS^FO960,440^FDBR ${fadCode}^FS
`;
};

export const getVATrow = (params: monarchHeadLabelParams): string => {
  const { terminalID, vatCode, sessionID, fadCode } = params;
  return `
^CF0,36^FO550,400^FD BR ${fadCode}  ${terminalID}-${sessionID} VAT ${vatCode}^FS
`;
};

export const getAddress = (params: monarchHeadLabelParams): string => {
  const { postcode, town, destAddress1, barCodeReq, destinationCountry } = params;
  let address = postcode ? postcode : town;
  address = address + ", " + destAddress1;

  // 40 symbols max to fit in the area
  const numberOfSymbolsToTrim = (destinationCountry + address).length > 30 ? address.length - 30 : 0;
  address = numberOfSymbolsToTrim > 0 ? address.slice(0, -numberOfSymbolsToTrim) : address;

  // if we don't have a barcode address is printed to the right of 2D
  let addressPosition = 200;
  if (barCodeReq === "true") {
    addressPosition = 400;
  }
  const countryPosition = addressPosition + 40;
  return `
^FO520,${addressPosition}^FD ${address}^FS
^CF0,33^FO680,${countryPosition}^FD${destinationCountry}^FS`;
};

export const generateOneDBarcode = (upuTrackingNumber: string): string => {
  return `^BY3,3,228^FO460,95^BCN,228,Y,N,N,A^FD${upuTrackingNumber}^FS`;
};

export const generateTwoDBarcode = (journeyParams: monarchHeadLabelParams, prnWithCheckDigit: string): string => {
  const { upuCountryId, infoTypeId, versionId, format, class: RMClass, mailType, mailPieceType } = additionalParams;
  const {
    fadCode,
    serviceId: product,
    date,
    upuTrackingNumber,
    weight,
    weightType,
    price,
    destAddress1,
    postcode,
    destinationCountry,
    returnToSenderPostCode,
    requiredAtDelivery,
  } = journeyParams;
  const padString = " ";
  const formatDate = date.replace(/\//g, "");

  const barcodeString =
    upuCountryId.padEnd(4, padString) +
    infoTypeId +
    versionId +
    format.padEnd(2, padString) +
    RMClass +
    mailType +
    prnWithCheckDigit +
    fadCode +
    (weight ? weight.padStart(7, "0") : "0000000") +
    weightType +
    price.padStart(5, "0") +
    formatDate +
    product +
    mailPieceType.padEnd(2, padString) +
    upuTrackingNumber.padEnd(13, padString) +
    destAddress1?.padEnd(35, padString) +
    postcode.padEnd(9, padString) +
    destinationCountry +
    returnToSenderPostCode.padEnd(7, padString) +
    (requiredAtDelivery || padString) +
    padString +
    padString;

  return barcodeString;
};
