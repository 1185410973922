import { toBlob } from "html-to-image";
import WebSocketAsPromised from "websocket-as-promised";
import { Response, SupportedServices } from "../../types";

import { isError } from "../../helpers";
import {
  ReceiptPrinterActions,
  ReceiptPrinterClient,
  ReceiptPrinterClientProps,
  ReceiptPrinterModes,
  ReceiptPrinterProps,
  ReceiptPrinterResult,
} from "./types";

const DEFAULT_WIDTH = 288; // 576px: printer | scaled : 2

const generateReceiptImageBlob = async (receipt: string, width?: number): Promise<string> => {
  // html-to-image expects the dom to exist in the node tree of the
  // document, it cannot frustratedly use HtmlElement's passed directly, so
  // we create a temp div which is hidden on screen and remove once processed
  document.getElementById("receipt-container")?.remove();
  const receiptContainer = document.createElement("div");
  receiptContainer.setAttribute("id", "receipt-container");

  // reason we hide it offscreen is because the library
  // interprets visible styles and if we hide, it doesn't render
  receiptContainer.style.position = "fixed";
  receiptContainer.style.bottom = "-1000px";
  receiptContainer.style.overflow = "hidden";

  const receiptElement = document.createElement("div");
  receiptElement.setAttribute("id", "generated-receipt");
  receiptElement.style.width = `${width || DEFAULT_WIDTH}px`;
  receiptElement.innerHTML = receipt;

  // add as a child of the container
  receiptContainer.appendChild(receiptElement);
  document.body.appendChild(receiptContainer);

  const blob = await toBlob(document.getElementById("generated-receipt") as HTMLElement, {
    pixelRatio: 2,
    skipAutoScale: true,
    type: "image/jpeg",
    backgroundColor: "white",
  });

  const reader = new FileReader();
  reader.readAsDataURL(blob as Blob);

  return new Promise((resolve) => {
    reader.onloadend = async () => {
      const receiptToBePrinted = (reader.result as string).split("base64,")[1];
      receiptContainer.remove();
      resolve(receiptToBePrinted);
    };
  });
};

const printMock = (receiptToBePrinted: string) => {
  const peripheralSelectorBroadcast: BroadcastChannel = new BroadcastChannel("Peripherals");
  peripheralSelectorBroadcast.postMessage("RECIEPT");
  peripheralSelectorBroadcast.close();
  setTimeout(() => {
    const printBroadcast: BroadcastChannel = new BroadcastChannel("receipt");
    printBroadcast.postMessage(receiptToBePrinted);
    printBroadcast.close();
  }, 500);
};

export const buildReceiptPrinterClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: ReceiptPrinterClientProps
): ReceiptPrinterClient => {
  const performAction = async (
    action: ReceiptPrinterActions,
    params: ReceiptPrinterProps
  ): Promise<ReceiptPrinterResult> => {
    const response = (await requestHandler({
      service: SupportedServices.ReceiptPrinter,
      action,
      params,
    })) as Response & ReceiptPrinterResult;

    if (isError(response)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  const print = async (receipt: string, mode?: ReceiptPrinterModes, width?: number): Promise<ReceiptPrinterResult> => {
    if (!mode || mode === ReceiptPrinterModes.Html) {
      const receiptToBePrinted = await generateReceiptImageBlob(receipt, width);

      if (props?.useMock) {
        printMock(receiptToBePrinted);
        return { printed: true };
      }

      return performAction(ReceiptPrinterActions.Print, {
        receipt: receiptToBePrinted,
        mode: ReceiptPrinterModes.Html,
      });
    } else {
      if (props?.useMock) {
        printMock(
          await generateReceiptImageBlob(
            `<pre style="margin: 0; padding: 0; box-sizing: border-box; font-family: monospace; font-size: 11px; color: black; user-select: none; margin: 0 auto; width: 42ch;">` +
              receipt +
              "</pre>"
          )
        );
        return { printed: true };
      }

      return performAction(ReceiptPrinterActions.Print, {
        receipt: receipt,
        mode: ReceiptPrinterModes.Text,
      });
    }
  };

  return Object.freeze({
    print,
  });
};
