export interface ReceiptPrinterResult {
  printed: boolean;
}

export interface ReceiptPrinterClient {
  print(receipt: string, mode?: string, width?: number): Promise<ReceiptPrinterResult>;
}
export interface ReceiptPrinterClientProps {
  useMock?: boolean;
}

export interface ReceiptPrinterProps {
  receipt: string;
  mode?: string;
}

export enum ReceiptPrinterActions {
  Print = "PRINT",
}

export enum ReceiptPrinterModes {
  Text = "text",
  Html = "html",
}

export enum ReceiptPrinterEvent {
  PRINTER_COVER_OPEN = "PRINTER_COVER_OPEN",
  PRINTER_OUT_OF_PAPER = "PRINTER_OUT_OF_PAPER",
  PRINTER_COVER_OK = "PRINTER_COVER_OK",
  PRINTER_PAPER_OK = "PRINTER_PAPER_OK",
  PRINTER_PAPER_NEAR_TO_EMPTY = "PRINTER_PAPER_NEAR_TO_EMPTY",
  PRINTER_IS_ONLINE = "PRINTER_IS_ONLINE",
  PRINTER_IS_OFFLINE = "PRINTER_IS_OFFLINE",
  PRINTER_OFF = "PRINTER_OFF",
  PRINTER_IS_OFF_AND_OFFLINE = "PRINTER_IS_OFF_AND_OFFLINE",
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
}
